import { encode } from 'js-base64';
import Cookies from 'js-cookie';
import { catchErrorSync } from '../app/components/Common/AppErrorBoundary';
import ApiInterface from '../app/modules/ApiInterface';
import Helper from '../app/modules/Helper';
import store from '../store';
import { sendDataToPassthruForm } from './baseActions';
import { loadFrontFormDataFromSessionStorage } from './baseActions.utils';
import { getFormData } from './flightActions';
import { SET_COMPARE_DATA, SET_COMPARE_RESULT, SET_FULL_SCREEN_INDEX } from './types';

export const setCompareData = (data) => {
	return catchErrorSync((dispatch) => {
		dispatch({
			type: SET_COMPARE_DATA,
			payload: data,
		});
	});
};

export const setCompareResult = (data) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: SET_COMPARE_RESULT,
			payload: data,
		});
	});

export const setCompareFullScreenIndex = (index) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: SET_FULL_SCREEN_INDEX,
			payload: index,
		});
	});

export const reverseCompare = () =>
	catchErrorSync((dispatch) => {
		const parsed = store.getState().compare.data;
		switch (store.getState().base.enabledVert) {
			case 'flights':
				// @ts-ignore
				const originIATA = parsed['origin-iata'];
				// @ts-ignore
				const destinationIATA = parsed['destination-iata'];
				// @ts-ignore
				parsed['origin-iata'] = destinationIATA;
				// @ts-ignore
				parsed['destination-iata'] = originIATA;
				break;
			case 'cars':
				// @ts-ignore
				const dropOffDestination = parsed['drop-off-destination'];
				// @ts-ignore
				const dropOffDestinationID = parsed['drop-off-destination-id'];
				// @ts-ignore
				const dropOffDestinationKey = parsed['drop-off-destination-key'];
				// @ts-ignore
				const pickupDestination = parsed['pickup-destination'];
				// @ts-ignore
				const pickupDestinationID = parsed['pickup-destination-id'];
				// @ts-ignore
				const pickupDestinationKey = parsed['pickup-destination-key'];
				// @ts-ignore
				parsed['pickup-destination'] = dropOffDestination;
				parsed['pickup-destination-id'] = dropOffDestinationID;
				parsed['pickup-destination-key'] = dropOffDestinationKey;
				// @ts-ignore
				parsed['drop-off-destination'] = pickupDestination;
				parsed['drop-off-destination-id'] = pickupDestinationID;
				parsed['drop-off-destination-key'] = pickupDestinationKey;
				break;
			default:
				break;
		}
		store.dispatch(setCompareData(parsed));
		store.dispatch(fetchCompareResult(parsed));
	});

export const fetchCompareResult = (data) =>
	catchErrorSync((dispatch) => {
		const params = new URLSearchParams();
		for (let i = 0; i < Object.keys(data).length; i++) {
			// @ts-ignore
			params.append(Object.keys(data)[i], data[Object.keys(data)[i]]);
		}
		ApiInterface.instance
			.kayakCompare(params)
			.then((res) => {
				if (res.data.kayakResponse.compareToItems.length === 0) {
					const customFormData = loadFrontFormDataFromSessionStorage();
					const isTooLittleCompanies = true;
					store.dispatch(
						sendDataToPassthruForm(customFormData || store.dispatch(getFormData('front')), isTooLittleCompanies)
					);
					return;
				}

				store.dispatch(setCompareResult(res.data));
				sessionStorage.setItem(`${store.getState().base.enabledVert}-compare-result`, JSON.stringify(res.data));
				for (let i = 0; i < res.data.kayakCookies.length; i++) {
					const cookieData = res.data.kayakCookies[i].split(';');
					const cookieName = cookieData[0].split('=')[0];
					const cookieValue = cookieData[0].split('=')[1];
					let expire = cookieData[2].split('=')[1];

					expire = Helper.getDiffDaysBetweenTwoDates(new Date(), new Date(expire));
					Cookies.set(cookieName, cookieValue, { expires: expire, path: '/', domain: res.data.kayakDomain });
				}
				res.data.kayakResponse.compareToItems.forEach(function (item) {
					ApiInterface.instance
						.kayakImpress({
							q: encode(item.impressionUrl),
							domain: res.data.kayakDomain,
						})
						.then((r) => {});
				});
			})
			.catch((err) => {
				console.log(err);
			});
	});

export const callKayakCompareAndSaveResultsToState = (data, isKcForm) => {
	store.dispatch(setCompareResult(null));
	store.dispatch(setCompareData(data));

	ApiInterface.instance
		.kayakCompare(data)
		.then((res) => {
			store.dispatch(setCompareResult(res.data));

			if (res.data.kayakResponse.compareToItems.length === 0) {
				const customFormData = loadFrontFormDataFromSessionStorage();
				const isTooLittleCompanies = true;
				const formToSend = customFormData || store.dispatch(getFormData('front'));

				if (Helper.isKayakCompareFlowFront()) {
					formToSend.f = 'k';
					formToSend.tab = 'front';
				} else if (Helper.isKayakCompareFlowBack()) {
					formToSend.b = 'k';
					formToSend.tab = 'back';
				}

				const openTwoTabs = Helper.isKayakCompareFlowFront();
				store.dispatch(sendDataToPassthruForm(formToSend, isTooLittleCompanies, openTwoTabs));
				// store.dispatch(setCompareResult({}))
				return;
			}
			sessionStorage.setItem(`${store.getState().base.enabledVert}-compare-result`, JSON.stringify(res.data));

			for (let i = 0; i < res.data.kayakCookies.length; i++) {
				const cookieData = res.data.kayakCookies[i].split(';');
				const cookieName = cookieData[0].split('=')[0];
				const cookieValue = cookieData[0].split('=')[1];
				let expire = cookieData[2].split('=')[1];

				expire = Helper.getDiffDaysBetweenTwoDates(new Date(), new Date(expire));
				Cookies.set(cookieName, cookieValue, { expires: expire, path: '/', domain: res.data.kayakDomain });
			}
			res.data.kayakResponse.compareToItems.forEach((item) => {
				ApiInterface.instance
					.kayakImpress({
						q: encode(item.impressionUrl),
						domain: res.data.kayakDomain,
					})
					.then((r) => {});
			});
		})
		.catch((err) => {
			console.log(err);
		});
};
