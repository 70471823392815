import {
	PASSTHRU_SET_FOUND_PARTNER,
	PASSTHRU_SET_FOUND_PARTNER_LINK,
	PASSTHRU_TOGGLE_REDIRECT_IMMEDIATELY,
	PassthruActionTypes,
	SET_DURATION_DETECT_REDIRECT_LINK,
	SET_TICKET_DATA,
} from '../actions/types';

interface PassthruState {
	foundLink: string | null;
	redirectImmediately: boolean;
	ticketData: object;
	durationDetectRedirectLink: number;
	partner: string;
}

const initialState: PassthruState = {
	foundLink: null,
	redirectImmediately: false,
	ticketData: {},
	durationDetectRedirectLink: 0,
	partner: '',
};
const passhtruReducer = (state = initialState, action: PassthruActionTypes) => {
	switch (action.type) {
		case PASSTHRU_SET_FOUND_PARTNER_LINK:
			return {
				...state,
				foundLink: action.payload,
			};
		case PASSTHRU_SET_FOUND_PARTNER:
			return {
				...state,
				partner: action.payload,
			};
		case PASSTHRU_TOGGLE_REDIRECT_IMMEDIATELY:
			return {
				...state,
				redirectImmediately: action.payload,
			};
		case SET_TICKET_DATA:
			return {
				...state,
				ticketData: action.payload,
			};
		case SET_DURATION_DETECT_REDIRECT_LINK: {
			return {
				...state,
				durationDetectRedirectLink: action.payload,
			};
		}

		default:
			return state;
	}
};

export default passhtruReducer;
