import Cookies from 'js-cookie';
import { useEffect, useMemo, useRef, useSyncExternalStore } from 'react';

const isJsonString = (str: string): boolean => {
	try {
		JSON.parse(str);
		return true;
	} catch (e) {
		return false;
	}
};

type Listener = () => void;

const cookieStore = () => {
	const cache = new Map<string, any>();
	const listeners = new Set<Listener>();
	const subscribe = (listener: Listener) => {
		listeners.add(listener);
		return () => listeners.delete(listener);
	};

	const notify = () => listeners.forEach((l) => l());

	const get = <T>(cookieName: string) => {
		const value = cache.get(cookieName);
		if (value) return value;
		const cookieValue = Cookies.get(cookieName);
		if (!cookieValue) return undefined;

		let cachedValue: T | undefined;
		try {
			const newValue = isJsonString(cookieValue) ? JSON.parse(cookieValue) : cookieValue;
			cachedValue = newValue as T;
		} catch (error) {
			console.error('Error parsing JSON from cookieValue:', error);
			cachedValue = cookieValue as unknown as T;
		}
		return cachedValue;
	};

	const update = <T>(cookieName: string, v: T) => {
		cache.set(cookieName, v);
		Cookies.set(cookieName, JSON.stringify(v));
		notify();
	};

	const remove = (cookieName: string) => {
		cache.delete(cookieName);
		Cookies.remove(cookieName);
		notify();
	};

	return {
		subscribe,
		get,
		update,
		remove,
	};
};

export const store = cookieStore();

export const useCookie = <T>(cookieName: string, serverCookieValue?: T) => {
	const getServerSnapshot = () => serverCookieValue;

	const [selector, update, remove] = useMemo(
		() => [(): T => store.get<T>(cookieName), (v: T) => store.update<T>(cookieName, v), () => store.remove(cookieName)],
		[cookieName]
	);

	const value = useSyncExternalStore(store.subscribe, selector, getServerSnapshot);

	const intervalIdRef = useRef<NodeJS.Timeout | null>(null);
	const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);

	useEffect(() => {
		if (!value) {
			intervalIdRef.current = setInterval(() => {
				const currentValue = store.get<T>(cookieName);
				if (currentValue) {
					update(currentValue);
					if (intervalIdRef.current) clearInterval(intervalIdRef.current);
				}
				console.log('checking for cookie value');
			}, 500);

			timeoutIdRef.current = setTimeout(() => {
				if (intervalIdRef.current) clearInterval(intervalIdRef.current);
			}, 10000);
		}

		return () => {
			if (intervalIdRef.current) clearInterval(intervalIdRef.current);
			if (timeoutIdRef.current) clearTimeout(timeoutIdRef.current);
		};
	}, [value, cookieName]);

	return [value as T, update, remove] as const;
};

export default useCookie;
