import { combineReducers } from 'redux';
import baseReducer from './baseReducer';
import compareReducer from './compareReducer';
import flightReducer from './flightReducer';
import growthbookReducer from './growthbookReducer';
import passthruReducer from './passthruReducer';

export default combineReducers({
	base: baseReducer,
	flight: flightReducer,
	passthru: passthruReducer,
	compare: compareReducer,
	growthbook: growthbookReducer,
});
