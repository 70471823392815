export const tripTypes = [
	{
		label: 'keywords.roundTrip',
		value: 'round-trip',
	},
	{
		label: 'keywords.oneWay',
		value: 'one-way',
	},
];
export const pricingTypes = [
	{
		label: 'keywords.economy',
		value: 'economy',
	},
	{
		label: 'keywords.business',
		value: 'business',
	},
	{
		label: 'keywords.first',
		value: 'first',
	},
	{
		label: 'keywords.premium',
		value: 'premium',
	},
];
