import { GrowthbookAction } from 'actions/growthbookActions';

interface State {
	growthbookCallback: any;
	features: Record<string, boolean>;
}

const initialState: State = {
	growthbookCallback: null,
	features: {},
};

interface ActionMap {
	[key: string]: (state: State, action: GrowthbookAction) => State;
}

const actionMap: ActionMap = {
	SET_GROWTHBOOK_CALLBACK: (state, action) => {
		return {
			...state,
			growthbookCallback: action.payload,
		};
	},
	SET_GROWTHBOOK_FEATURE_ENABLED: (state, action) => {
		return {
			...state,
			features: {
				...state.features,
				[action.payload.featureId]: action.payload.isEnabled,
			},
		};
	},
};

const reducer = (state: State = initialState, action: GrowthbookAction): State => {
	const reducerFn = actionMap[action.type];
	return reducerFn ? reducerFn(state, action) : state;
};

export default reducer;
