import i18n from 'i18next';
import queryString from 'query-string';
// @ts-ignore
import animateScrollTo from 'animated-scroll-to';
import { localeMap } from 'app/modules/Constants';
import { formatISO, isAfter } from 'date-fns';
import { enGB } from 'date-fns/locale';
import { IAirportItem } from '../components/Common/AirportSelect/Item';

class Helper {
	static isEmpty(value: any) {
		if (value === null) return true;
		if (typeof value === 'string' || Array.isArray(value)) return value.length === 0;
		if (Helper.isObject(value)) return Object.keys(value).length === 0;
		if (!value) return true;

		return false;
	}

	static isObject(obj: object) {
		return obj != null && obj.constructor.name === 'Object';
	}

	static isBoolean(variable: any) {
		return typeof variable === 'boolean';
	}

	static capitalize = (s: string) => {
		if (typeof s !== 'string') return '';
		return s.charAt(0).toUpperCase() + s.slice(1);
	};

	static groupBy = (xs: any, key: any) => {
		// @ts-ignore
		return xs.reduce(function (rv, x) {
			(rv[x[key]] = rv[x[key]] || []).push(x);
			return rv;
		}, {});
	};

	static toLowerCaseKeys(obj: object) {
		let key;
		const keys = Object.keys(obj);
		let n = keys.length;
		const newobj: object = {};
		while (n--) {
			key = keys[n];
			// @ts-ignore
			newobj[key.toLowerCase()] = obj[key];
		}
		return newobj;
	}

	static parseQueryString(str: string, lowerCaseKeys?: boolean) {
		lowerCaseKeys = lowerCaseKeys || false;
		let parsed = queryString.parse(str);
		if (lowerCaseKeys) {
			// @ts-ignore
			parsed = Helper.toLowerCaseKeys(parsed);
		}
		return parsed;
	}

	static ISODateString(d: Date) {
		function pad(n: number) {
			return n < 10 ? '0' + n : n;
		}

		return (
			d.getUTCFullYear() +
			'-' +
			pad(d.getUTCMonth() + 1) +
			'-' +
			pad(d.getUTCDate()) +
			'T' +
			pad(d.getUTCHours()) +
			':' +
			pad(d.getUTCMinutes()) +
			':' +
			pad(d.getUTCSeconds()) +
			'Z'
		);
	}

	static ISODateTimeString(d: Date, t: string) {
		function pad(n: number) {
			return n < 10 ? '0' + n : n;
		}

		return `${d.getUTCFullYear()}-${pad(d.getUTCMonth() + 1)}-${pad(d.getUTCDate())}T${t}:00Z`;
	}

	static isNumeric(n: any) {
		return !isNaN(parseFloat(n)) && isFinite(n);
	}

	static chunk = (arr: Array<any>, size: number) =>
		Array.from({ length: Math.ceil(arr.length / size) }, (v, i) => arr.slice(i * size, i * size + size));

	static getElementY(query: string) {
		// @ts-ignore
		return window.pageYOffset + document.querySelector(query).getBoundingClientRect().top;
	}

	static doScrolling(element: string, duration: number, verticalOffset = 0) {
		const domElement = document.querySelector(element);

		if (domElement) {
			animateScrollTo(domElement, {
				minDuration: duration,
				verticalOffset,
			});
		}
		return;
	}

	static addHiddenInputToForm(form: HTMLFormElement, name: string, value: string) {
		if (form) {
			const input = document.createElement('input');
			input.type = 'hidden';
			input.name = name;
			input.setAttribute('value', value);
			form.appendChild(input);
		}
	}

	static serialize = function (obj: object) {
		const str = [];
		for (const p in obj) {
			if (obj.hasOwnProperty(p)) {
				// @ts-ignore
				str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
			}
		}
		return str.join('&');
	};

	static isIsoDate(str: string) {
		if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
		const d = new Date(str);
		return d.toISOString() === str;
	}

	static replaceAll(target: string, search: string, replacement: any) {
		const escapedSearch = search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
		return target.replace(new RegExp(escapedSearch, 'gi'), replacement);
	}

	static isValidEmail(email: string) {
		const re =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}

	static trans(key: string, keys?: Record<string, unknown>) {
		keys = keys || {};
		return i18n?.t(key, keys);
	}

	static bool(string: string) {
		string = string && string.toLowerCase();
		return string === 'true';
	}

	static getOrigin(url: string) {
		if (/^\/\//.test(url)) {
			// no scheme, use current scheme, extract domain
			url = window.location.protocol + url;
		} else if (/^\//.test(url)) {
			// just path, use whole origin
			url = window.location.origin + url;
		}
		// @ts-ignore
		return url.match(/^([^/]+\/\/[^/]+)/)[0];
	}

	static isDateAfter(date: Date) {
		return isAfter(new Date(), date);
	}

	static isValidDate(date: any) {
		return date && Object.prototype.toString.call(date) === '[object Date]' && !isNaN(date);
	}

	static searchByValue = (items: Array<any>, value: string) => {
		for (let i = 0; i < items.length; i++) {
			if (value === items[i].value) {
				return items[i];
			}
		}
		return {};
	};

	static isValidTime(time: string) {
		const pattern = /^\d{2,}:\d{2}$/;
		return pattern.test(time);
	}

	static convertToSelectDataForAdminRule(data: Array<any>) {
		return data.map((item) => ({ value: item.value, label: item.value === 0 ? 'All' : item.name }));
	}

	static convertObjectValueForAdminRule(data: Array<any>) {
		// @ts-ignore
		return Object.keys(data).map((item) => ({ value: data[item], label: data[item] }));
	}

	static getBaseUrl(url: string) {
		if (url) {
			const parts = url.split('://');

			if (parts.length > 1) {
				return parts[0] + '://' + parts[1].split('/')[0];
			} else {
				return parts[0].split('/')[0];
			}
		}
	}

	static isFromEU(countryCode: string) {
		const europeCountries = [
			'AT',
			'IT',
			'BE',
			'LV',
			'BG',
			'LT',
			'HR',
			'LU',
			'CY',
			'MT',
			'CZ',
			'NL',
			'DK',
			'PL',
			'EE',
			'PT',
			'FI',
			'RO',
			'FR',
			'SK',
			'DE',
			'SI',
			'GR',
			'ES',
			'HU',
			'SE',
			'IE',
			'GB',
		];
		return europeCountries.indexOf(countryCode) !== -1;
	}

	static convertVapidKey(base64String: string) {
		const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
		const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
		const rawData = window.atob(base64);
		// @ts-ignore
		return Uint8Array.from([...rawData].map((char) => char.charCodeAt(0)));
	}

	static getQueryString() {
		let search = window.location.search;
		if (!search) return '';
		if (search.substr(0, 1) === '/') search = search.substr(1, search.length);
		return search;
	}

	static getLink(path: string) {
		let queryString = '';
		if (typeof window != 'undefined') queryString = Helper.getQueryString();
		return path + queryString;
	}

	static hasPT(number: number) {
		return Helper.checkQueryStringKeyValue('pt', number.toString());
	}

	static checkQueryStringKeyValue(key: string | number, value: string | string[]) {
		if (typeof window === 'undefined') return;
		const data = Helper.parseQueryString(window.location.search, true);

		if (Array.isArray(value)) {
			return data.hasOwnProperty(key) && value.indexOf(data[key] as string) !== -1;
		}

		return data.hasOwnProperty(key) && data[key] === value;
	}

	static isValidDateFormat(dateString: string) {
		const regEx = /^\d{4}-\d{2}-\d{2}$/;
		if (!dateString.match(regEx)) return false; // Invalid format
		const d = new Date(dateString);
		const dNum = d.getTime();
		if (!dNum && dNum !== 0) return false; // NaN value, Invalid date
		return d.toISOString().slice(0, 10) === dateString;
	}

	static isValidTimeFormat(dateString: string) {
		const regEx = /^\d{2}:00$/;
		return dateString.match(regEx);
	}

	static convertStringDateToDateObject(dateString: string) {
		const dateStringA = dateString.split('-');
		// @ts-ignore
		return new Date(dateStringA[0], parseInt(dateStringA[1]) - 1, dateStringA[2]);
	}

	static getSelectedLocation(selected: IAirportItem, width: number) {
		let selectedItems = [];
		if (selected.city) selectedItems.push(selected.city);
		else if (selected.name) selectedItems.push(selected.name);
		if (selected.country) selectedItems.push(selected.country);
		let selectedItemsString = selectedItems.join(', ');
		if (selected.iata) selectedItemsString = selectedItemsString + ` (${selected.iata}) `;
		const countChar = selectedItemsString.length;
		if (countChar * 6 > width) {
			selectedItems = [];
			if (selected.city) selectedItems.push(selected.city);
			else if (selected.name) selectedItems.push(selected.name);
			selectedItemsString = selectedItems.join(', ');
			if (selected.iata) selectedItemsString = selectedItemsString + ` (${selected.iata}) `;
		}
		return selectedItemsString;
	}

	static convertSlugToName(slug: string) {
		return slug
			.split('-')
			.map((item) => item.charAt(0).toUpperCase() + item.slice(1))
			.join(' ');
	}
	// @ts-ignore
	static shallowEqual(object1, object2) {
		const keys1 = Object.keys(object1);
		const keys2 = Object.keys(object2);
		if (keys1.length !== keys2.length) {
			return false;
		}
		for (const key of keys1) {
			if (object1[key] !== object2[key]) {
				return false;
			}
		}
		return true;
	}
	static getDiffDaysBetweenTwoDates(date1: Date, date2: Date): number {
		// @ts-ignore
		const diffTime = Math.abs(date2 - date1);
		return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
	}
	static getLocaleByCode = (code: string) => {
		if (code !== 'en') {
			return localeMap[code];
		}
		return enGB;
	};

	static toISODateTimeString(date: Date, hour = '00:00') {
		const [hours, minutes] = hour.split(':').map(Number);

		const newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes);

		return formatISO(newDate);
	}
	static checkForQueryStringKey(key: string | number) {
		const data = Helper.parseQueryString(window.location.search, true);
		return data.hasOwnProperty(key);
	}

	static checkWindowSize = () => {
		if (typeof window !== 'undefined') {
			return window.innerWidth;
		}
		return 0;
	};

	static isMobile = () => {
		return Helper.checkWindowSize() < 768;
	};

	static isDesktop = () => {
		return Helper.checkWindowSize() >= 1200;
	};

	static parseJwt(token: string) {
		const base64Url = token.split('.')[1];
		const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
		const jsonPayload = decodeURIComponent(
			atob(base64)
				.split('')
				.map((c) => {
					return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
				})
				.join('')
		);
		return JSON.parse(jsonPayload);
	}

	static getQueryObject() {
		if (typeof window === 'undefined') return {};

		return Helper.parseQueryString(window.location.search, true);
	}

	static isKayakCompareFlowFront() {
		const data = this.getQueryObject();
		return data.hasOwnProperty('f') && data['f'] === 'kc';
	}

	static isKayakCompareFlowBack() {
		const data = this.getQueryObject();
		return data.hasOwnProperty('b') && data['b'] === 'kc';
	}
}

export default Helper;
