import { IAirportItem } from '../app/components/Common/AirportSelect/Item';

export const OPEN_INPUT = 'OPEN_INPUT';
export const OPEN_CHILD_INPUT = 'OPEN_CHILD_INPUT';
export const SET_TOOLTIP_ERROR = 'SET_TOOLTIP_ERROR';
export const REMOVE_ERRORS = 'REMOVE_ERRORS';
export const TOGGLE_SHOW_ERRORS = 'TOGGLE_SHOW_ERRORS';
export const ENABLE_VERT = 'ENABLE_VERT';
export const AGREE_COOKIE_NOTICE = 'AGREE_COOKIE_NOTICE';
export const TOGGLE_PROGRESS_LOADING = 'TOGGLE_PROGRESS_LOADING';
export const TOGGLE_AUTO_JUMP = 'TOGGLE_AUTO_JUMP';
export const PASSTHRU_TOGGLE_REDIRECT_IMMEDIATELY = 'PASSTHRU_TOGGLE_REDIRECT_IMMEDIATELY';

export const SET_COUNTRY_CODE = 'SET_COUNTRY_CODE';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_VAPID_KEY = 'SET_VAPID_KEY';
export const SET_PRICE_ALERT = 'SET_PRICE_ALERT';

export const SET_IS_BROWSER_SUPPORT_NOTIFICATION = 'SET_IS_BROWSER_SUPPORT_NOTIFICATION';
export const SET_PUSH_SUBSCRIPTION = 'SET_PUSH_SUBSCRIPTION';
export const SET_PRICE_ALERT_EMAIL = 'SET_PRICE_ALERT_EMAIL';
export const TOGGLE_PREVENT_ENTER = 'TOGGLE_PREVENT_ENTER';
export const TOGGLE_PREVENT_FOCUS = 'TOGGLE_PREVENT_FOCUS';
export const TOGGLE_SHOW_PHONE_NUMBER = 'TOGGLE_SHOW_PHONE_NUMBER';
export const SET_PHONE_NUMBER = 'SET_PHONE_NUMBER';
export const SET_TICKET_DATA = 'SET_TICKET_DATA';
export const SET_DURATION_DETECT_REDIRECT_LINK = 'SET_DURATION_DETECT_REDIRECT_LINK';
export const TOGGLE_PRICE_ALERT_HANDLER = 'TOGGLE_PRICE_ALERT_HANDLER';
export const PRICE_ALERT_SHOW_MODAL_SIGN_UP = 'PRICE_ALERT_SHOW_MODAL_SIGN_UP';
export const PRICE_ALERT_SET_IS_CLOSED_SIGN_UP_MODAL = 'PRICE_ALERT_SET_IS_CLOSED_SIGN_UP_MODAL';
export const PASSTHRU_SET_FOUND_PARTNER_LINK = 'PASSTHRU_SET_FOUND_PARTNER_LINK';
export const PASSTHRU_SET_FOUND_PARTNER = 'PASSTHRU_SET_FOUND_PARTNER';

export const FLIGHT_SELECT_FROM_DATE = 'FLIGHT_SELECT_FROM_DATE';
export const FLIGHT_SELECT_TO_DATE = 'FLIGHT_SELECT_TO_DATE';
export const FLIGHT_SELECT_FROM_LOCATION = 'FLIGHT_SELECT_FROM_LOCATION';
export const FLIGHT_SELECT_TO_LOCATION = 'FLIGHT_SELECT_TO_LOCATION';
export const FLIGHT_SELECT_TRIP_TYPE = 'FLIGHT_SELECT_TRIP_TYPE';
export const FLIGHT_SELECT_PRICING_TYPE = 'FLIGHT_SELECT_PRICING_TYPE';
export const FLIGHT_SET_COUNT_ADULTS = 'FLIGHT_SET_COUNT_ADULTS';
export const FLIGHT_SET_COUNT_CHILDREN = 'FLIGHT_SET_COUNT_CHILDREN';
export const SET_COMPARE_DATA = 'SET_COMPARE_DATA';
export const SET_COMPARE_RESULT = 'SET_COMPARE_RESULT';
export const SET_FULL_SCREEN_INDEX = 'SET_FULL_SCREEN_INDEX';

export const SET_INIT_PAYLOAD = 'SET_INIT_PAYLOAD';

export const SET_UID_IMID = 'SET_UID_IMID';

export const USER_SIGNIN_SUCCESS = 'USER_SIGNIN_SUCCESS';
export const SIGNIN_CHECKBOX_CHECKED = 'SIGNIN_CHECKBOX_CHECKED';

interface ISetUIDAndIMID {
	type: typeof SET_UID_IMID;
	payload: {
		uid: string;
		imid: string;
	};
}

interface IOpenInputAction {
	type: typeof OPEN_INPUT;
	payload: string | null;
}

interface ISetInitPayloadAction {
	type: typeof SET_INIT_PAYLOAD;
	payload: any;
}

interface IOpenChildInputAction {
	type: typeof OPEN_CHILD_INPUT;
	payload: string | null;
}

interface ISetTooltipErrorsAction {
	type: typeof SET_TOOLTIP_ERROR;
	payload: object;
}

interface IRemoveErrorsAction {
	type: typeof REMOVE_ERRORS;
}

interface IToggleShowErrorsAction {
	type: typeof TOGGLE_SHOW_ERRORS;
	payload: boolean;
}

interface IEnableVertAction {
	type: typeof ENABLE_VERT;
	payload: string;
}

interface IEnableAgreeCookieNoticeAction {
	type: typeof AGREE_COOKIE_NOTICE;
	payload: boolean;
}

interface IToggleProgressLoadingAction {
	type: typeof TOGGLE_PROGRESS_LOADING;
	payload: boolean;
}

interface IToggleAutoJumpAction {
	type: typeof TOGGLE_AUTO_JUMP;
	payload: boolean;
}

interface IPassthruToggleRedirectImmediatelyAction {
	type: typeof PASSTHRU_TOGGLE_REDIRECT_IMMEDIATELY;
	payload: boolean;
}

interface ISetCountryCodeAction {
	type: typeof SET_COUNTRY_CODE;
	payload: string;
}

interface ISetLanguageCodeAction {
	type: typeof SET_LANGUAGE;
	payload: any;
}

interface ISetVapidKeyCodeAction {
	type: typeof SET_VAPID_KEY;
	payload: string;
}

interface ISetPriceAlertCodeAction {
	type: typeof SET_PRICE_ALERT;
	payload: string[];
}

interface ISetIsBrowserSupportNotificationAction {
	type: typeof SET_IS_BROWSER_SUPPORT_NOTIFICATION;
	payload: boolean;
}

interface ISerPriceAlertEmailAction {
	type: typeof SET_PRICE_ALERT_EMAIL;
	payload: string;
}

interface ITogglePreventEnterAction {
	type: typeof TOGGLE_PREVENT_ENTER;
	payload: boolean;
}

interface ITogglePreventFocusAction {
	type: typeof TOGGLE_PREVENT_FOCUS;
	payload: boolean;
}

interface IToggleShowNumberAction {
	type: typeof TOGGLE_SHOW_PHONE_NUMBER;
	payload: boolean;
}

interface ISetPhoneNumberAction {
	type: typeof SET_PHONE_NUMBER;
	payload: string;
}

interface ISetTicketDataAction {
	type: typeof SET_TICKET_DATA;
	payload: object;
}

interface ISetDurationDetectRedirectLinkAction {
	type: typeof SET_DURATION_DETECT_REDIRECT_LINK;
	payload: number;
}

interface ITogglePriceAlertHandlerAction {
	type: typeof TOGGLE_PRICE_ALERT_HANDLER;
	payload: boolean;
}

interface IPriceAlertShowModalSignUpAction {
	type: typeof PRICE_ALERT_SHOW_MODAL_SIGN_UP;
	payload: boolean;
}

interface IPriceAlertSetIsClosedSignUpModalAction {
	type: typeof PRICE_ALERT_SET_IS_CLOSED_SIGN_UP_MODAL;
	payload: boolean;
}
interface IPassthruSetFoundPartnerLink {
	type: typeof PASSTHRU_SET_FOUND_PARTNER_LINK;
	payload: string | null;
}
interface IPassthruSetFoundPartner {
	type: typeof PASSTHRU_SET_FOUND_PARTNER;
	payload: string;
}

interface IUserSigninSuccess {
	type: typeof USER_SIGNIN_SUCCESS;
	payload: boolean;
}

interface IUserSigninCheckboxChecked {
	type: typeof SIGNIN_CHECKBOX_CHECKED;
	payload: boolean;
}

export type BaseActionTypes =
	| IOpenInputAction
	| IOpenChildInputAction
	| ISetTooltipErrorsAction
	| IRemoveErrorsAction
	| IToggleShowErrorsAction
	| IEnableVertAction
	| IEnableAgreeCookieNoticeAction
	| IToggleProgressLoadingAction
	| IToggleAutoJumpAction
	| ISetCountryCodeAction
	| ISetLanguageCodeAction
	| ISetVapidKeyCodeAction
	| ISetPriceAlertCodeAction
	| ISetIsBrowserSupportNotificationAction
	| ISerPriceAlertEmailAction
	| ITogglePreventEnterAction
	| ITogglePreventFocusAction
	| IToggleShowNumberAction
	| ISetPhoneNumberAction
	| ITogglePriceAlertHandlerAction
	| IPriceAlertShowModalSignUpAction
	| IPriceAlertSetIsClosedSignUpModalAction
	| ISetInitPayloadAction
	| ISetUIDAndIMID
	| IUserSigninSuccess
	| IUserSigninCheckboxChecked;

export type PassthruActionTypes =
	| ISetTicketDataAction
	| IPassthruToggleRedirectImmediatelyAction
	| ISetDurationDetectRedirectLinkAction
	| IPassthruSetFoundPartnerLink
	| IPassthruSetFoundPartner;

interface IFlightSelectFromDateAction {
	type: typeof FLIGHT_SELECT_FROM_DATE;
	payload: Date;
}

interface IFlightSelectToDateAction {
	type: typeof FLIGHT_SELECT_TO_DATE;
	payload: Date;
}

interface IFlightSelectFromLocationAction {
	type: typeof FLIGHT_SELECT_FROM_LOCATION;
	payload?: IAirportItem;
}

interface IFlightSelectToLocationAction {
	type: typeof FLIGHT_SELECT_TO_LOCATION;
	payload?: IAirportItem;
}

interface IFlightSelectTripTypeAction {
	type: typeof FLIGHT_SELECT_TRIP_TYPE;
	payload?: IAirportItem;
}

interface IFlightSetCountChildrenAction {
	type: typeof FLIGHT_SET_COUNT_CHILDREN;
	payload?: IAirportItem;
}

interface IFlightSetCountAdultsAction {
	type: typeof FLIGHT_SET_COUNT_ADULTS;
	payload?: IAirportItem;
}

interface IFlightSelectPricingTypeAction {
	type: typeof FLIGHT_SELECT_PRICING_TYPE;
	payload?: IAirportItem;
}

export type FlightActionTypes =
	| IFlightSelectFromDateAction
	| IFlightSelectToDateAction
	| IFlightSelectFromLocationAction
	| IFlightSelectToLocationAction
	| IFlightSelectTripTypeAction
	| IFlightSetCountChildrenAction
	| IFlightSetCountAdultsAction
	| IFlightSelectPricingTypeAction;
