import { configureStore } from '@reduxjs/toolkit';
import { useMemo } from 'react';
import thunkMiddleware from 'redux-thunk';
import reducers from './reducers';

let store;

function initStore(initialState) {
	return configureStore({
		reducer: reducers,
		preloadedState: initialState,
		middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(thunkMiddleware),
		devTools: process.env.NODE_ENV !== 'production',
	});
}

export const initializeStore = (preloadedState) => {
	let _store = store ?? initStore(preloadedState);

	// After navigating to a page with an initial Redux state, merge that state
	// with the current state in the store, and create a new store
	if (preloadedState && store) {
		_store = initStore({
			...store.getState(),
			...preloadedState,
		});
		// Reset the current store
		store = undefined;
	}

	// For SSG and SSR always create a new store
	if (typeof window === 'undefined') return _store;
	// Create the store once in the client
	if (!store) store = _store;

	return _store;
};

export function useStore(initialState) {
	return useMemo(() => initializeStore(initialState), [initialState]);
}
if (!store) {
	store = initStore();
}
export default store;
