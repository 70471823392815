import axios from 'axios';
import { attach as raxAttach } from 'retry-axios';
import Helper from './Helper';

const singleton = Symbol();
const singletonEnforcer = Symbol();

const fbService = process.env.NEXT_PUBLIC_FB_EVENT_SERVICE;

class ApiInterface {
	constructor(enforcer) {
		if (enforcer !== singletonEnforcer) {
			throw new Error('Cannot construct singleton');
		}
		let origin = window.location.origin;
		if (origin === undefined) {
			origin = Helper.getBaseUrl(window.location.toString());
		}
		this.session = axios.create({
			baseURL: process.env.NEXT_PUBLIC_BACKEND_URL,
			headers: {
				Accept: 'application/json',
			},
			withCredentials: true,
		});
		this.session.defaults.timeout = 20 * 1000;
		this.session.defaults.validateStatus = (status) => status >= 200 && status < 300;
		this.session.defaults.raxConfig = {
			instance: this.session,
			retry: 3,
			noResponseRetries: 3,
			httpMethodsToRetry: ['GET', 'HEAD', 'OPTIONS', 'DELETE', 'PUT', 'POST'],
			retryDelay: 500,
			httpStatusCodesToRetry: [
				[100, 199],
				[420, 429],
				[500, 599],
			],
			statusCodesToRetry: [
				[100, 199],
				[429, 429],
				[500, 599],
			],
			onRetryAttempt: (err) => {},
		};
		raxAttach(this.session);
		this.session.interceptors.response.use(
			(response) => {
				return response;
			},
			(error) => {
				const raxConfig = error.config.raxConfig;
				const { retry } = raxConfig;

				// If the custom currentRetryAttempt property is not set, initialize it to 0
				if (!error.config.currentRetryAttempt) {
					error.config.currentRetryAttempt = 0;
				}

				// If it's the last retry, report the error
				if (error.config.currentRetryAttempt === retry) {
					console.log('OkHttp: Last Retry Attempt', error);
				} else {
					// If it's not the last retry, increment the currentRetryAttempt
					error.config.currentRetryAttempt += 1;
				}
				return Promise.reject(error);
			}
		);

		this.session.interceptors.request.use((request) => {
			// console.log('OkHttp: Starting Request', request);
			return request;
		});
	}

	static get instance() {
		// Try to get an efficient singleton
		if (!this[singleton]) {
			this[singleton] = new ApiInterface(singletonEnforcer);
		}

		return this[singleton];
	}

	get = (...params) => this.session.get(...params);
	post = (...params) => this.session.post(...params);
	put = (...params) => this.session.put(...params);
	patch = (...params) => this.session.patch(...params);
	remove = (...params) => this.session.delete(...params);
	suggestAirports = (params) =>
		this.session.get('/suggest-airports', {
			params,
		});
	suggestCities = (params) =>
		this.session.get('/suggest-cities', {
			params,
		});
	init = (path, params) => this.session.get(path + '/init', { params });
	fetchAirport = (code, params) =>
		this.session.get('/airport/' + code, {
			params,
		});
	fetchCity = (code, params) =>
		this.session.get('/city/' + code, {
			params,
		});
	redirect = (vert, data) =>
		this.session.post(`/${vert}/redirect`, data, {
			headers: { 'Content-Type': 'multipart/form-data' },
		});
	clientRedirect = (vert, data, cfg) => this.session.post(`/${vert}/client-redirect`, data, cfg);
	newsletterSubscription = (vert, data) =>
		this.session.post(`/${vert}/subscription`, data, {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		});
	contactUs = (data) =>
		this.session.post(`/contact-us`, data, {
			headers: {
				'Content-Type': 'application/json',
			},
		});
	searchByZipcode = (params) =>
		this.session.get('/suggest-zipcodes', {
			params,
		});
	fetchZipCode = (code, params) =>
		this.session.get('/zipcode/' + code, {
			params,
		});
	kayakCompare = (data) =>
		this.session.post(`/flights/kayak-compare`, data, {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		});
	kayakImpress = (data) =>
		this.session.get(`/flights/kayak-impress`, {
			params: data,
		});
	fetchKayakHeadersGo = ({ q, domain }) =>
		this.session.post('/kayak-headers', {
			q,
			domain,
		});
	fetchKayakHeadersPython = ({ q, domain }) =>
		this.session.get('', {
			baseURL: 'https://get-response-headers-proxy-cvhlx3hjxq-uc.a.run.app/',
			params: {
				url: new URL(`https://${domain}/${q}`).toString(),
				referer: window.location.protocol + '//' + window.location.host,
			},
			withCredentials: false,
		});

	createFbSearchEvent = (data) =>
		this.session.post(`${fbService}/fb_search_events`, data, {
			headers: {
				'x-api-siteid': 'Smarterflyercom',
			},
		});

	experiment = (vertical, data) =>
		this.session.post(`/${vertical}/experiment?api-key=${process.env.NEXT_PUBLIC_API_KEY}`, data, {});
}

export default ApiInterface;
