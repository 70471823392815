import { formatISO, isBefore, isValid, subDays } from 'date-fns';
import { catchErrorSync } from '../app/components/Common/AppErrorBoundary';
import i18n from '../app/locales/i18n';
import Helper from '../app/modules/Helper';
import store from '../store';
import { getFbClientId } from '../utils/cookies';
import {
	emitGMTEvent,
	hideAndShowErrors,
	sendDataToCompare,
	sendDataToPassthruForm,
	setTicketData,
	setTooltipError,
	submitForm,
	togglePriceAlertSignUpModal,
	toggleProgressLoading,
} from './baseActions';
import { saveFrontFormDataToSessionStorage } from './baseActions.utils';
import {
	FLIGHT_SELECT_FROM_DATE,
	FLIGHT_SELECT_FROM_LOCATION,
	FLIGHT_SELECT_PRICING_TYPE,
	FLIGHT_SELECT_TO_DATE,
	FLIGHT_SELECT_TO_LOCATION,
	FLIGHT_SELECT_TRIP_TYPE,
	FLIGHT_SET_COUNT_ADULTS,
	FLIGHT_SET_COUNT_CHILDREN,
} from './types';

import { pricingTypes } from 'app/components/Flight/data';

import { callKayakCompareAndSaveResultsToState } from 'actions/compareActions';

export const selectFromDate = (date) =>
	catchErrorSync((dispatch) => {
		if (!isValid(date) || isBefore(date, subDays(new Date(), 1))) {
			return;
		}

		if (date.getTime() > store.getState().flight.selectedToDate.getTime()) {
			store.dispatch(selectToDate(date));
		}

		dispatch({
			type: FLIGHT_SELECT_FROM_DATE,
			payload: date,
		});
	});

export const selectToDate = (date) =>
	catchErrorSync((dispatch) => {
		if (!isValid(date) || isBefore(date, subDays(new Date(), 1))) {
			return;
		}
		dispatch({
			type: FLIGHT_SELECT_TO_DATE,
			payload: date,
		});
	});

export const selectFromLocation = (location) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: FLIGHT_SELECT_FROM_LOCATION,
			payload: location,
		});
		if (!Helper.isEmpty(location) && !store.getState().base.preventFocus && !Helper.isMobile()) {
			document.getElementById('airport-to-input').focus();
			document.getElementById('airport-to-input').select();
		}
		const errors = { ...store.getState().base.tooltipErrors };
		delete errors['airport-from'];
		store.dispatch(setTooltipError(errors));
	});

export const selectToLocation = (location) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: FLIGHT_SELECT_TO_LOCATION,
			payload: location,
		});
		const errors = { ...store.getState().base.tooltipErrors };
		delete errors['airport-to'];
		store.dispatch(setTooltipError(errors));
	});

export const selectTripType = (tripType) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: FLIGHT_SELECT_TRIP_TYPE,
			payload: tripType,
		});
	});

export const selectPricingType = (pricingType) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: FLIGHT_SELECT_PRICING_TYPE,
			payload: pricingType,
		});
	});

export const setCountAdults = (count) =>
	catchErrorSync((dispatch) => {
		count = parseInt(count);
		if (count >= 1 && count <= 9) {
			dispatch({
				type: FLIGHT_SET_COUNT_ADULTS,
				payload: count,
			});
		}
	});

export const setCountChildren = (count) =>
	catchErrorSync((dispatch) => {
		count = parseInt(count);
		if (count >= 0 && count <= 9) {
			dispatch({
				type: FLIGHT_SET_COUNT_CHILDREN,
				payload: count,
			});
		}
	});

export const checkInputsForErrors = (errors) => {
	const flightState = store.getState().flight;
	const { selectedFromLocation, selectedToLocation } = flightState;

	if (!Object.keys(selectedFromLocation).length) {
		errors['airport-from'] = i18n.t('validation.flights.fromLocation');
	}
	if (!Object.keys(selectedToLocation).length) {
		errors['airport-to'] = i18n.t('validation.flights.toLocation');
	}

	if (selectedFromLocation.iata && selectedToLocation.iata) {
		if (selectedFromLocation.iata === selectedToLocation.iata) {
			errors['airport-to'] = i18n.t('validation.flights.sameLocations');
		}
	}
};

export const doSearchFlights = (compare) =>
	catchErrorSync((dispatch) => {
		compare = compare || false;
		const priceAlert = store.getState().base.priceAlert;
		const baseState = store.getState().base;
		const errors = {};
		const email = store.getState().base.priceAlertEmail;

		if (priceAlert.indexOf('email') !== -1 && email && !Helper.isValidEmail(email)) {
			errors['price-alert-email'] = i18n.t('validation.priceAlert.email');
		}

		checkInputsForErrors(errors);
		store.dispatch(setTooltipError(errors));
		if (
			Helper.isEmpty(errors) &&
			!baseState.isClosedPriceAlertSignUpModal &&
			baseState.priceAlertHandlerChecked &&
			Helper.isDesktop()
		) {
			store.dispatch(togglePriceAlertSignUpModal(true));
			return;
		}

		if (Helper.isEmpty(errors)) {
			store.dispatch(emitGMTEvent(store.getState().base.enabledVert));
			const parsed = Helper.parseQueryString(window.location.search, true);
			let preventOpenFrontTab = false;
			if (parsed.hasOwnProperty('f') && parsed.f === '0') preventOpenFrontTab = true;
			let openBackTabTimeout = 500;
			if (preventOpenFrontTab) openBackTabTimeout = 100;
			setTimeout(() => {
				const formData = store.dispatch(getFormData('front'));
				saveFrontFormDataToSessionStorage(formData);
				if (compare) {
					store.dispatch(sendDataToCompare(formData));
				} else if (!preventOpenFrontTab) store.dispatch(submitFormFront());
				if (!compare) {
					setTimeout(() => {
						store.dispatch(submitFormBack());
					}, openBackTabTimeout);
				}
			}, 10);
		} else {
			store.dispatch(hideAndShowErrors());
		}
	});

export const doSearchPartners = (isKcForm = false) =>
	catchErrorSync(() => {
		const priceAlert = store.getState().base.priceAlert;
		const baseState = store.getState().base;
		const email = store.getState().base.priceAlertEmail;
		const errors = {};

		if (priceAlert.indexOf('email') !== -1 && email && !Helper.isValidEmail(email)) {
			errors['price-alert-email'] = i18n.t('validation.priceAlert.email');
		}

		checkInputsForErrors(errors);
		store.dispatch(setTooltipError(errors));

		if (
			Helper.isEmpty(errors) &&
			!baseState.isClosedPriceAlertSignUpModal &&
			baseState.priceAlertHandlerChecked &&
			Helper.isDesktop()
		) {
			store.dispatch(togglePriceAlertSignUpModal(true));
			return;
		}

		if (Helper.isEmpty(errors)) {
			const frontFromData = store.dispatch(getFormData('front'));
			for (const key in frontFromData) {
				if (key.startsWith('_')) {
					const newKey = key.replace('_', '');
					const dataArray = frontFromData[key].split('|');
					frontFromData[newKey] = dataArray.map((e) => parseInt(e, 10)).reduce((acc, a) => acc + a, 0);
					delete frontFromData[key];
				}
			}
			callKayakCompareAndSaveResultsToState(frontFromData, isKcForm);
		} else {
			store.dispatch(setTooltipError(errors));
		}
	});

export const submitFormBack = () =>
	catchErrorSync((dispatch) => {
		const form = document.getElementById('redirect-back-form');
		const data = store.dispatch(getFormData('back'));
		store.dispatch(setTicketData(data));
		store.dispatch(toggleProgressLoading(true));
		store.dispatch(submitForm(form, data));
	});

export const submitFormFront = () =>
	catchErrorSync((dispatch) => {
		store.dispatch(sendDataToPassthruForm(store.dispatch(getFormData('front'))));
	});

export const getFormData = (formType) => {
	return catchErrorSync((dispatch) => {
		const data = store.getState().flight;
		const from = data.selectedFromLocation;
		const to = data.selectedToLocation;
		const dateFrom = data.selectedFromDate;
		const dateTo = data.selectedToDate;
		const tripType = data.selectedTripType;
		const pricingType = data.selectedPricingType;
		const countAdults = data.countAdults;
		const countChildren = data.countChildren;

		// RENTAL CONTRACT DURATION
		const date_from = dateFrom;
		const date_to = dateTo;

		const diffTime = Math.abs(date_to - date_from);
		const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
		const dta = Math.abs(date_from - Date.now());
		const dtaDiff = Math.ceil(dta / (1000 * 60 * 60 * 24));

		const fbp = getFbClientId();

		const formData = {
			tab: formType,
			vert: store.getState().base.enabledVert,
			'origin-iata': from.iata,
			'destination-iata': to.iata,
			'departure-time': formatISO(dateFrom),
			'cabin-class': pricingType.value,
			'num-adults': countAdults,
			'num-youth': countChildren,
			'rental-duration': diffDays,
			dta: dtaDiff,
			...(fbp && { fbp }),
		};
		if (formType === 'back') {
			const pushSubscription = store.getState().base.pushSubscription;
			const email = store.getState().base.priceAlertEmail;
			if (!Helper.isEmpty(pushSubscription)) {
				formData['web-push-subscription'] = JSON.stringify(pushSubscription);
			} else if (email) {
				formData['email'] = email;
			}
		}
		if (tripType.value === 'round-trip') {
			formData['return-time'] = formatISO(dateTo);
		}
		return formData;
	});
};

export const prePopulateDataFromQueryString = () =>
	catchErrorSync((dispatch) => {
		const parsed = Helper.parseQueryString(window.location.search, true);
		if (parsed.hasOwnProperty('date-from') && parsed['date-from']) {
			let dateFrom = parsed['date-from'];
			if (Helper.isValidDateFormat(dateFrom)) {
				dateFrom = Helper.convertStringDateToDateObject(dateFrom);
				store.dispatch(selectFromDate(dateFrom));
			}
		}
		if (parsed.hasOwnProperty('date-to') && parsed['date-to']) {
			let dateTo = parsed['date-to'];
			if (Helper.isValidDateFormat(dateTo)) {
				dateTo = Helper.convertStringDateToDateObject(dateTo);
				store.dispatch(selectToDate(dateTo));
			}
		}
		if (parsed.hasOwnProperty('cabin') && parsed['cabin']) {
			const cabin = parsed['cabin'];
			const pricingType = pricingTypes.find((type) => type.value.startsWith(cabin));

			if (pricingType) {
				store.dispatch(selectPricingType(pricingType));
			}
		}
	});
