import { add, startOfToday } from 'date-fns';
import {
	FLIGHT_SELECT_FROM_DATE,
	FLIGHT_SELECT_FROM_LOCATION,
	FLIGHT_SELECT_PRICING_TYPE,
	FLIGHT_SELECT_TO_DATE,
	FLIGHT_SELECT_TO_LOCATION,
	FLIGHT_SELECT_TRIP_TYPE,
	FLIGHT_SET_COUNT_ADULTS,
	FLIGHT_SET_COUNT_CHILDREN,
	FlightActionTypes,
} from '../actions/types';
import { IAirportItem } from '../app/components/Common/AirportSelect/Item';
import { pricingTypes, tripTypes } from '../app/components/Flight/data';
import Helper from '../app/modules/Helper';

interface FlightState {
	selectedFromDate: Date;
	selectedToDate: Date;
	selectedPricingType: string;
	selectedTripType: string;
	countChildren: number;
	countAdults: number;
	selectedFromLocation?: IAirportItem | object;
	selectedToLocation?: IAirportItem | object;
	enableReturnLocation: boolean;
}

const initialState: FlightState = {
	enableReturnLocation: false,
	selectedFromDate: startOfToday(),
	selectedToDate: add(new Date(), { days: 7 }),
	selectedFromLocation: {},
	selectedToLocation: {},
	selectedPricingType: Helper.searchByValue(pricingTypes, 'economy'),
	selectedTripType: Helper.searchByValue(tripTypes, 'round-trip'),
	countAdults: 1,
	countChildren: 0,
};
const flightReducer = (state = initialState, action: FlightActionTypes) => {
	switch (action.type) {
		case FLIGHT_SELECT_FROM_DATE:
			return {
				...state,
				selectedFromDate: action.payload,
			};
		case FLIGHT_SELECT_TO_DATE:
			return {
				...state,
				selectedToDate: action.payload,
			};
		case FLIGHT_SELECT_FROM_LOCATION:
			return {
				...state,
				selectedFromLocation: action.payload,
			};
		case FLIGHT_SELECT_TO_LOCATION:
			return {
				...state,
				selectedToLocation: action.payload,
			};
		case FLIGHT_SELECT_TRIP_TYPE:
			return {
				...state,
				selectedTripType: action.payload,
			};
		case FLIGHT_SET_COUNT_CHILDREN:
			return {
				...state,
				countChildren: action.payload,
			};
		case FLIGHT_SET_COUNT_ADULTS:
			return {
				...state,
				countAdults: action.payload,
			};
		case FLIGHT_SELECT_PRICING_TYPE:
			return {
				...state,
				selectedPricingType: action.payload,
			};
		default:
			return state;
	}
};
export default flightReducer;
