import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';

i18n
	.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.use(resourcesToBackend((language: string, namespace: string) => import(`../locales/${language}/${namespace}.json`)))
	.init({
		debug: false,
		fallbackLng: 'en',
		supportedLngs: ['en', 'es', 'de', 'it', 'pt', 'fr'],
		ns: ['translation'],
		interpolation: {
			escapeValue: false,
		},
		detection: {
			order: ['querystring'],
		},
	});

export default i18n;
